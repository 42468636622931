<template>
  <div>
    <div v-if="messages.thread">
      <b-card class="border-primary">
        <b-card-title class="border-bottom d-flex justify-content-between">
          <h3>{{ messages.thread.title }}</h3>
          <p>
            <small>{{ threadDate | date_time_format_thread }}</small>
          </p>
        </b-card-title>
        <ul class="review-message" ref="messageList">
          <li
            class="p-1 rounded mb-1 main_class_message"
            :class="
              message.message_by == null ? 'bg-primary text-white' : 'bg-light '
            "
            style="list-style: none; margin-right: 10px; margin-left: 5px"
            v-for="(message, index) in messages.messages"
            :key="index"
          >
            {{ message.message }}
            <div class="text-right">
               <small>{{ message.created_at | date_time_format_short }}</small>
            </div>
          </li>
        </ul>
        <div class="send-box">
          <Input
            v-model="form.message"
            placeholder="Enter something..."
            style="width: 80%"
          />
          <Button
            class="ml-1"
            type="primary"
            @click="sendMessage"
            :disabled="!sendMessageSucces"
          >
            <feather-icon icon="SendIcon" size="16"
          /></Button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardTitle,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";

export default {
  props: ["id", "threadDate"],
  data() {
    return {
      form: new Form({
        message: null,
        candidate_thread_id: null,
      }),
      messages: [],
      sendMessageSucces: true,
    };
  },
  mounted() {
    this.getMessage();
    this.scrollToBottom();
  },
  methods: {
    Clear() {
      this.form.message = null;
    },

    getMessage() {
      this.form.candidate_thread_id = this.id;
      axios
        .get("/candidate/message/" + this.form.candidate_thread_id)
        .then((res) => {
          this.messages = res.data.messages;
          this.form.message = null;
          this.messageShow = true;
          this.scrollToBottom();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    sendMessage() {
      this.sendMessageSucces = false;
      this.form
        .post("/candidate/message")
        .then((res) => {
          this.getMessage();
          this.s(res.data.message);
          this.sendMessageSucces = true;
          this.Clear();
        })
        .catch((e) => {
          this.sendMessageSucces = true;
          this.e(e.response.data.message);
        });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messageList = this.$refs.messageList;
        if (messageList) {
          messageList.scrollTop = messageList.scrollHeight;
        }
      });
    },
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BCardTitle,
  },
};
</script>

<style scoped>
.review-message {
  height: 400px;
  overflow-y: scroll;
  border: none;
}
.review-message .card .send-box {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 5px;
}
</style>
