<template>
  <div>
      <div v-for="(thread, index) in threads.candidate_theads"
      :key="index" class="mb-2"
      v-if="threads.candidate_theads.length"
      >
          <ReviewMessage :id="thread.id" :threadDate="thread.created_at"/>
      </div>

  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import ReviewMessage from "./ReviewMessage.vue";
export default {
  data() {
    return {
    threads: [],
    };
  },
  mounted() {
    this.getThreads();
  },
  methods: {
    getThreads() {
      axios
        .get("/candidate/thread")
        .then((res) => {
          this.threads = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

  },
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BCardTitle,
    ReviewMessage,
  },
};
</script>
